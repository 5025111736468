import React, { ChangeEvent, FormEvent, useState } from "react"

import axios from "axios"

const hostUrl = process.env.GATSBY_BACKEND_HOST

const flapmaxlogo = require("../images/faviconflapmax.png")

const Footer = () => {
  const [email, setEmail] = useState("")
  const [showSubscription, setShowSubscription] = useState(false)
  const [requestStatus, setRequestStatus] = useState({
    received: false,
    response: "",
  })

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
  }

  const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    axios
      .post(`${hostUrl}/main-newsletter/add-contact`, { email: email })
      .then(res => {
        setRequestStatus({ received: true, response: "Success" })
      })
      .catch(err => {
        setRequestStatus({
          received: true,
          response: "Uh oh, we had a problem subscribing you.",
        })
      })
  }

  return (
    <footer className="footer footer-sm">
      <div className="footer-content">
        <ul className="list-inline social-circle text-center">
          <li className="list-inline-item">
            <a href="https://www.linkedin.com/company/flapmax/">
              <i className="zmdi zmdi-linkedin"></i>
            </a>
          </li>
           <li className="list-inline-item">
             <a href="https://twitter.com/FlapmaxHQ">
              <i className="zmdi zmdi-twitter"></i>
            </a>
          </li>
          {/* <li className="list-inline-item">
            <a href="https://twitter.com/flapmaxhq">
              <i className="zmdi zmdi-twitter"></i>
            </a>
          </li> */}
          {/*<li className="list-inline-item">
            <span onClick={() => setShowSubscription(prev => !prev)}>
              <i className="zmdi zmdi-email"></i>
            </span>
          </li>*/}
        </ul>
        {showSubscription && (
          <form onSubmit={handleFormSubmit}>
            <p>Flapmax Updates</p>
            {requestStatus.received && (
              <p
                style={
                  requestStatus.response === "Success"
                    ? { color: "green" }
                    : { color: "red" }
                }
              >
                {requestStatus.response}
              </p>
            )}
            <div className="form-group">
              <input
                className="form-control"
                type="email"
                name="email"
                value={email}
                onChange={handleChange}
                placeholder="Email Address"
                required
              />
              <button className="btn btn-primary" type="submit">
                Subscribe
              </button>
            </div>
          </form>
        )}
      </div>

      <p className="text-center mb-0 mt-3">
        © Flapmax 2024 All Rights Reserved
      </p>
      <img className="logo " src={flapmaxlogo} />
    </footer>
  )
}

export default Footer

