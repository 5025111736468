const $ = require("jquery")

/**
 * Takes in a list of urls and maps them to selected navlinks.
 * @param {array} urlArray An array of objects including
 * a URL string and a target (blank, self, etc).
 */
export const makeNavLinks = function (urlArray) {
  // Getting element to attach link to
  let links = $(".dropdown-toggle.nav-link")

  // Attaching links to elements as href attribute
  links.each(function (index) {
    if (index === 5) {
      return
    }

    $(this).attr("href", urlArray[index].href)
    $(this).on("click", function (e) {
      e.preventDefault()
      var url = $(this).attr("href")
      window.open(url, urlArray[index].target)
    })
  })
}
