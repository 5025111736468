import React, { useState, useEffect } from "react"

// SSR-friendly way to grab window width
export const useWindowWidth = () => {
  const windowGlobal = typeof window !== "undefined"

  if (windowGlobal) {
    const [width, setWidth] = useState(window.innerWidth)

    useEffect(() => {
      const handleResize = () => setWidth(window.innerWidth)
      window.addEventListener("resize", handleResize)
      return () => {
        window.removeEventListener("resize", handleResize)
      }
    })

    return width
  }
}
