import React, { useEffect, useState } from "react"
import { Navbar, Nav, NavDropdown } from "react-bootstrap"
import { useLocation } from "@reach/router"
import AfricaSubNav from "./africa/subnav"
import { Link } from "gatsby"
import { FaArrowRight } from "react-icons/fa"
import { RiArrowDropDownLine } from "react-icons/ri"
import { useWindowWidth } from "../utils/useWindowWidth"

const { makeNavLinks } = require("../_js/makeNavLinks")

interface Props {
  siteTitle: string
}

const scaigatelogo = require("../images/scaigatelogo.png")
const lanlogo = require("../images/lanlogo.png")
const pcslogo = require("../images/pcslogo.png")

const Header = ({ siteTitle }: Props) => {
  const location = useLocation()
  const [showDrop, setShowDrop] = useState({
    sustainability: false,
    platform: false,
    developer: false,
    university: false,
    africa: false,
    apply: false,
    partners: false,
  })

  const windowWidth = useWindowWidth()

  useEffect(() => {
    // List of URLs to attach (in order from left-to-right)
    const urls = [
      {
        href: "/#",
        target: "_self",
      },
      // {
      //   href: "/partners",
      //   target: "_self",
      // },
      {
        href: "https://sustainability.flapmax.com/",
        target: "_blank",
      },
      {
        href: "https://developer.flapmax.com/",
        target: "_blank",
      },
      {
        href: "https://university.flapmax.com/",
        target: "_target",
      },
      {
        href: "/africa",
        target: "_self",
      },
       {
        href: "https://lnkd.in/echSaaF7",
        target: "_target",
      },
    ]

    // upon mounting component, attach links to dropdown titles
    makeNavLinks(urls)
  }, [])

  /**
   * Displays a dropdown for a given navlink.
   * @param name The name of the tab to display a dropdown for.
   */
  const handleShow = (name: string) => {
    const tabsToClose: any = {}
    // closing all tabs other than opened
    Object.keys(showDrop)
      .filter(key => key !== name)
      .forEach(key => (tabsToClose[key] = false))
    setShowDrop({ ...tabsToClose, [name]: true })
  }

  /**
   * Hides a dropdown for a given navlink.
   * @param name The name of the tab to hide a dropdown for.
   */
  const handleClose = (name: string) => {
    setShowDrop({ ...showDrop, [name]: false })
  }

  // Check if any dropdowns are active
  const anyDropdownActive = Object.values(showDrop).some(value => value)

  return (
    <header>
      <div className="nav-container">
        <Navbar
          variant="dark"
          expand="lg"
          className={`boot-nav main  ${
            location.pathname.includes("/africa") ? "min-scroll" : ""
          }`}
        >
          <Navbar.Brand to="/" as={Link}>
            Flapmax
          </Navbar.Brand>
          {/* Only render the Navbar.Toggle if anyDropdownActive is true */}
          {anyDropdownActive && <Navbar.Toggle aria-controls="basic-navbar-nav" />}
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="navbar-right">
              {/* 

 <div className="drop-link-wrapper">
                <NavDropdown
                  title="Platform"
                  id="basic-nav-dropdown"
                  show={showDrop.platform}
                  onMouseEnter={() => handleShow("platform")}
                  onMouseLeave={() => handleClose("platform")}
                >
                  <NavDropdown.Item to="/platforms/measure" as={Link}>
                    Measure
                  </NavDropdown.Item>

                  <NavDropdown.Item to="/platforms/coral-imaging" as={Link}>
                    Coral Imaging
                  </NavDropdown.Item>
                </NavDropdown>
                <RiArrowDropDownLine
                  className={showDrop.platform ? "flipped" : ""}
                  onClick={() => {
                    if (showDrop.platform) {
                      handleClose("platform")
                    } else {
                      handleShow("platform")
                    }
                  }}
                />
              </div>
              {/* <div className="drop-link-wrapper">
                <NavDropdown
                  title="Partners"
                  id="basic-nav-dropdown"
                  show={showDrop.partners}
                  onMouseEnter={() => handleShow("partners")}
                  onMouseLeave={() => handleClose("partners")}
                >
                  <NavDropdown.Item to="/partners/industry" as={Link}>
                    Industry
                  </NavDropdown.Item>
                  <NavDropdown.Item to="/partners/academic" as={Link}>
                    Academic
                  </NavDropdown.Item>
                </NavDropdown>
                <RiArrowDropDownLine
                  className={showDrop.partners ? "flipped" : ""}
                  onClick={() => {
                    if (showDrop.partners) {
                      handleClose("partners")
                    } else {
                      handleShow("partners")
                    }
                  }}
                />
              </div> 
              <div className="drop-link-wrapper">
                <NavDropdown
                  as="a"
                  href="#"
                  target="_blank"
                  title="Sustainability"
                  show={false}
                  id="basic-nav-dropdown"
                />
              </div>
              Render different dropdown for mobile views
            <div className="drop-link-wrapper">
                <NavDropdown
                  as="a"
                  href="#"
                  target="_blank"
                  title="Developer"
                  show={false}
                  id="basic-nav-dropdown"
                />
              </div>
              <div className="drop-link-wrapper">
                <NavDropdown
                  title="University"
                  id="basic-nav-dropdown"
                  show={showDrop.university}
                  onMouseEnter={() => handleShow("university")}
                  onMouseLeave={() => handleClose("university")}
                >
                  <NavDropdown.Item
                    href="https://university.flapmax.com/student"
                    target="_blank"
                  >
                    Students
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    href="https://university.flapmax.com/educator"
                    target="_blank"
                  >
                    Educators
                  </NavDropdown.Item>

                  <NavDropdown.Item
                    href="https://university.flapmax.com/industry"
                    target="_blank"
                  >
                    Corporates
                  </NavDropdown.Item>
                </NavDropdown>
                <RiArrowDropDownLine
                  className={showDrop.university ? "flipped" : ""}
                  onClick={() => {
                    if (showDrop.university) {
                      handleClose("university")
                    } else {
                      handleShow("university")
                    }
                  }}
                />
              </div>

              <div className="drop-link-wrapper">
                <NavDropdown
                  title="Africa"
                  id="basic-nav-dropdown"
                  show={showDrop.africa}
                  onMouseEnter={() => handleShow("africa")}
                  onMouseLeave={() => handleClose("africa")}
                >
                  <NavDropdown.Item  href="https://fastaccelerator.com"
                    target="_blank">
                    FAST
                  </NavDropdown.Item>

                  <NavDropdown.Item to="/africa/laboratory" as={Link}>
                    Laboratory
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    to="/africa/fast-accelerator/office-space"
                    as={Link}
                  >
                    Office Spaces
                  </NavDropdown.Item>
                </NavDropdown>
                <RiArrowDropDownLine
                  className={showDrop.africa ? "flipped" : ""}
                  onClick={() => {
                    if (showDrop.africa) {
                      handleClose("africa")
                    } else {
                      handleShow("africa")
                    }
                  }}
                />
              </div>
              
              
               <div className="drop-link-wrapper">
                <NavDropdown
                  title="Apply"
                  id="basic-nav-dropdown"
                  show={showDrop.apply}
                  onMouseEnter={() => handleShow("apply")}
                  onMouseLeave={() => handleClose("apply")}
                >
                  <NavDropdown.Item
                    href="https://lnkd.in/echSaaF7"
                    target="_blank"
                  >
                    Internship
                  </NavDropdown.Item>
                                   <NavDropdown.Item
                    href="https://forms.office.com/r/5q14gwN5QQ"
                    target="_blank"
                  >
                   OneApi - ML
                  </NavDropdown.Item>

                  <NavDropdown.Item
                    href="https://forms.office.com/r/NNdi0NNwvc"
                    target="_blank"
                  >
                    OneApi - 3D
                  </NavDropdown.Item>
                </NavDropdown>
                <RiArrowDropDownLine
                  className={showDrop.apply ? "flipped" : ""}
                  onClick={() => {
                    if (showDrop.apply) {
                      handleClose("apply")
                    } else {
                      handleShow("apply")
                    }
                  }}
                />
              </div> 




*/}
            </Nav>
          </Navbar.Collapse>
        </Navbar> 
{/* {location.pathname.includes("/africa") && <AfricaSubNav />} */}
      </div> 
    </header>
  )
}

export default Header
